// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-blog-post-js": () => import("./../src/components/templates/blog-post.js" /* webpackChunkName: "component---src-components-templates-blog-post-js" */),
  "component---src-components-templates-case-study-js": () => import("./../src/components/templates/case-study.js" /* webpackChunkName: "component---src-components-templates-case-study-js" */),
  "component---src-components-templates-page-data-js": () => import("./../src/components/templates/page-data.js" /* webpackChunkName: "component---src-components-templates-page-data-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-customers-js": () => import("./../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-data-before-after-covid-office-statistics-js": () => import("./../src/pages/data/before-after-covid-office-statistics.js" /* webpackChunkName: "component---src-pages-data-before-after-covid-office-statistics-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innovations-js": () => import("./../src/pages/innovations.js" /* webpackChunkName: "component---src-pages-innovations-js" */),
  "component---src-pages-partners-js": () => import("./../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-solutions-js": () => import("./../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-trust-center-js": () => import("./../src/pages/trust-center.js" /* webpackChunkName: "component---src-pages-trust-center-js" */)
}

