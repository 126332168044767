/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("./src/fonts/fonts.css")
require("./src/css/main.scss")

// polyfills
require("url-search-params-polyfill")

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== "undefined") {
    checkHash(location)
  }
}

const checkHash = location => {
  let { hash } = location
  if (!hash) return
  let ele = document.querySelector(`${hash}`)
  if (!ele) return

  if (hash) {
    setTimeout(() => {
      window.scrollTo({
        top: ele.offsetTop - 30,
        behavior: "smooth",
      })
    }, 250)
  }
}
